.LandingPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.LandingPage-Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Images/Background/background.jpg");
}


.LandingPage-Copyright-Container {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  cursor: default;
  opacity: 0.85;
  color: #eeeeff;
  font-size: 13px;
  font-weight: 100;
  font-style: italic;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.LandingPage-Copyright-Container:hover {
  opacity: 1;
}


/* If the screen size is 900px wide or less */
@media screen and (max-width: 900px) {
  .LandingPage-Title {
    font-size: 54px;
  }
}

/* If the screen size is 800px wide or less or 150px tall or less */
@media screen and (max-height: 150px) {
  .LandingPage-Copyright-Container {
    bottom: 5px;
  }
}
